"use strict"

$(document).on("turbolinks:load", function() {
  activate_minus_plus()
})

function activate_minus_plus() {
  $("body").on("click", ".product_buy-minus", product_count_minus_handler)
  $("body").on("click", ".product_buy-plus", product_count_plus_handler)
}

function product_count_minus_handler(event) {
  // console.log('event: ', event)
  // console.log('this: ', this)
  var input = $(this).siblings(".product_buy-count").first()
  var count = parseFloat(input.val())
  var minimum = parseFloat(input.data("minimum"))
  if (isNaN(minimum) || isNaN(count)) {
    return null
  }
  // Если не кратно минимальному - делаем кратным, в большую сторону.
  if ((count * 1000) % (minimum * 1000) > 0) {
    count = (Math.floor(count / minimum) + 1) * minimum
    // Просто округляем правильно
    count = Math.round(count * 1000) / 1000
  }
  count = count - minimum
  // Просто округляем правильно
  count = Math.round(count * 1000) / 1000
  var min = 0
  if (count < min) {
    count = min
  }
  input.val(count)
}

function product_count_plus_handler(event) {
  // console.log('event: ', event)
  // console.log('this: ', this)
  var input = $(this).siblings(".product_buy-count").first()
  var count = parseFloat(input.val())
  var minimum = parseFloat(input.data("minimum"))
  if (isNaN(minimum) || isNaN(count)) {
    return null
  }
  // Если не кратно минимальному - делаем кратным, в большую сторону.
  if ((count * 1000) % (minimum * 1000) > 0) {
    count = (Math.floor(count / minimum) + 1) * minimum
    // Просто округляем правильно
    count = Math.round(count * 1000) / 1000
  }
  count = count + minimum
  // Просто округляем правильно
  count = Math.round(count * 1000) / 1000
  input.val(count)
}
