"use strict"

$(document).on("turbolinks:load", function() {
  activate_carts_product_count()
})

function activate_carts_product_count() {
  $("body").on("click", ".carts_product_count-minus", carts_product_count_minus_handler)
  $("body").on("click", ".carts_product_count-plus", carts_product_count_plus_handler)
  $("body").on("change", ".carts_product_count-count", carts_product_count_count_handler)
}

function carts_product_count_minus_handler(event) {
  var input = $(this).siblings(".carts_product_count-count").first()
  var count = parseFloat(input.val())
  var minimum = parseFloat(input.data("minimum"))
  if (isNaN(minimum) || isNaN(count)) {
    return null
  }
  count = count - minimum
  // Просто округляем правильно
  count = Math.round(count * 1000) / 1000
  var min = 0
  if (count < min) {
    count = min
  }
  input.val(count).trigger("change")
}

function carts_product_count_plus_handler(event) {
  var input = $(this).siblings(".carts_product_count-count").first()
  var count = parseFloat(input.val())
  var minimum = parseFloat(input.data("minimum"))
  if (isNaN(minimum) || isNaN(count)) {
    return null
  }
  count = count + minimum
  // Просто округляем правильно
  count = Math.round(count * 1000) / 1000
  input.val(count).trigger("change")
}

function carts_product_count_count_handler(event) {
  var input = $(this)
  var count = parseFloat(input.val())
  var product_id = parseInt(input.data("product_id"))
  if (isNaN(count) || isNaN(product_id)) {
    return null
  }
  var ajax_timeout_id = input.data("ajax_timeout_id")
  if (ajax_timeout_id != null) {
    clearTimeout(ajax_timeout_id)
  }
  input.data("ajax_timeout_id", setTimeout(carts_product_count_set, 600, product_id, count))
}

function carts_product_count_set(product_id, count) {
  carts_product_count_ajax(product_id, count).done(carts_product_count_ajax_callback)
}

function carts_product_count_ajax(product_id, count) {
  return $.ajax({
    type: "get",
    url: `carts_products/set/${product_id}`,
    data: {
      count: count
    },
    cache: false,
    timeout: 600
  })
  .fail(function(jqXHR, textStatus, errorThrown) {
    console.log('jqXHR.responseJSON: ', jqXHR.responseJSON)
    console.log('jqXHR.responseText: ', jqXHR.responseText)
  })
}

function carts_product_count_ajax_callback(data) {
}
