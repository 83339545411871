import { Tooltip } from "bootstrap"

$(document).on('turbolinks:load', function() {
  // [].slice.call(... - поместить найденные ноды в массив, а не в объект NodeList.
  // var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  // var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
  //   return new Tooltip(tooltipTriggerEl)
  // })

  // Новые вставленнын элементы не работают без $('body')...
  $('body').tooltip({
    selector: '[data-bs-toggle="tooltip"]'
  })
})
