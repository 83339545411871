// Подсказки hint
//
// Стилевой класс tooltip уже зарезервирован bootstrap, поэтому подсказки будут
// hint.

import { Tooltip } from "bootstrap"

$(document).on('turbolinks:load', function() {

  // Подсказки текстовые с иконкой знака вопроса работают по наведению. В
  // админке выводится обычно с помощью хелперов string_info или field_info.
  //
  // `[].slice.call()` - поместить найденные ноды в массив, а не в объект
  // NodeList, иначе map не работает.
  var string_tooltip_doms = [].slice.call(document.querySelectorAll('.hint-string[title]'))
  var string_tooltips = string_tooltip_doms.map((e, i, a) => {
    return new Tooltip(e, {
      boundary: document.body
    })
  })

  // Подсказки с html-разметкой (пропускает не все теги, см allowList в
  // документации). Выводятся с помощью хелпера html_info, ну или ручками.
  new Array().forEach.call(document.querySelectorAll('.hint-html[title]'), (e, i, a) => {
    new Tooltip(e, {
      boundary: document.body,
      html: true,
      trigger: "click focus",
      customClass: "tooltip-white"
    })
  })

  // Подсказки на произвольном html элементе.
  Array.from(document.querySelectorAll(".hint[title]")).forEach((e, i, a) => {
    new Tooltip(e, {
      boundary: document.body
    })
  })
})
