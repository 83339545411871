"use strict"

function AffiliatesMenu() {
}

function GeoLocation() {
}

AffiliatesMenu.activate = function() {
  var affiliates_menu = $(".affiliates_menu")
  if (affiliates_menu.length == 0) {
    return null
  }
  affiliates_menu.on("click", ".dropdown-item", function(event) {
    var button = $(this)
    var affiliate_id = button.data("affiliate_id")
    Cookies.set("current_affiliate_id", affiliate_id)
    window.location.reload()
  })
}

GeoLocation.start = function() {
  if (navigator.cookieEnabled === false) {
    console.log('> Cookies отключены.')
    return null
  }
  if (!navigator.geolocation) {
    console.log('> Geolocation is not supported in this browser.')
    return null
  }
  var lat = Cookies.get("lat")
  var lon = Cookies.get("lon")
  if (lat != null && lon != null) {
    return null
  }
  // Определение геолокации
  // navigator.geolocation.getCurrentPosition(this.success.bind(this), this.error.bind(this))
  // Определение геолокации с опциями
  navigator.geolocation.getCurrentPosition(
    this.success.bind(this),
    this.error.bind(this),
    {
      // Продолжительность кэширования, милисекунд или Infinity.
      maximumAge: Infinity,
      // Как долго определять геолокацию до вызова callback с ошибкой, если 0, то вызов не происходит. В милисекундах.
      timeout: 5000,
      enableHighAccuracy: false
    }
  )
  // Отслеживание геолокации
  // navigator.geolocation.watchPosition(
  //   this.success.bind(this),
  //   this.error.bind(this),
  //   {
  //     // Продолжительность кэширования, милисекунд или Infinity.
  //     maximumAge: 0,
  //     // Как долго определять геолокацию до вызова callback с ошибкой, если 0, то вызов не происходит. В милисекундах.
  //     timeout: 5000,
  //     enableHighAccuracy: false
  //   }
  // )
}

GeoLocation.success = function(position) {
  var lat = position.coords.latitude
  var lon = position.coords.longitude
  // Тест координат Красноярска
  // var lat = 55.936397
  // var lon = 92.984078
  if (typeof lat !== 'number' || isNaN(lat) || typeof lon !== 'number' || isNaN(lon) ) {
    console.log('> lat or lon is not a number.')
    return null
  }
  Cookies.set("lat", lat)
  Cookies.set("lon", lon)
  Cookies.set("current_affiliate_id", null)
  window.location.reload()
}

GeoLocation.error = function() {
  console.log('> Geolocation error from this browser.')
}

$(document).on('turbolinks:load', function() {
  GeoLocation.start()
  AffiliatesMenu.activate()
})
