"use strict"


// Модуль отображения карты с выбранным текущим филиалом.
function AffiliateMap() {
}

AffiliateMap.map = null

AffiliateMap.start = function() {
  var affiliate_map = $("#affiliate_map")
  if (affiliate_map.length == 0) {
    return null
  }
  // ymaps - дожидается загрузки API и готовности DOM.
  window.ymaps.ready(this.init_affiliate_map, affiliate_map)
}

AffiliateMap.init_affiliate_map = function(data) {
  // Определим какие координаты расположим в центре.
  var lat, lng
  lat = this.data("affiliate_lat")
  lng = this.data("affiliate_lng")
  var coordinates = lat != "" && lng != "" ? [lat, lng] : [55.76, 37.64]

  // Создание экземпляра карты и его привязка к контейнеру с
  // заданным id.
  AffiliateMap.map = new ymaps.Map(
    'affiliate_map',
    {
      // При инициализации карты обязательно нужно указать
      // её центр и коэффициент масштабирования.
      center: coordinates, // Москва
      zoom: 9,
      controls: ['zoomControl', 'typeSelector',  'fullscreenControl', 'routeButtonControl']
    },
    {
      // searchControlProvider: 'yandex#search'
      searchControlProvider: null
    }
  )

  // document.getElementById('destroyButton').onclick = function () {
  //   // Для уничтожения используется метод destroy.
  //   myMap.destroy()
  // }

  AffiliateMap.add_geo_objects.call(this)
}

// Филиалы и текущее местоположение пользователя, ой нет, браузера, ой нет,
// провайдера интернет, ну вы поняли...
AffiliateMap.add_geo_objects = function() {
  // Филиалы, надеемся что у всех заполнены латитуд и лонгитуд.
  var affiliates = this.data("affiliates")
  for (var i = 0, l = affiliates.length; i < l; i++) {
    var affiliate = affiliates[i]
    if (affiliate.lat == null || affiliate.lng == null) {
      continue
    }
    AffiliateMap.map.geoObjects.add(
      new ymaps.Placemark(
        [affiliate.lat, affiliate.lng],
        {
          iconCaption: affiliate.ui_name,
          balloonContent: `филиал <strong>${affiliate.ui_name}</strong>`,
        },
        {
          preset: 'islands#dotIcon',
          iconColor: '#F57507',
          // iconCaptionMaxWidth: '50',
        }
      )
    )
  }
  // Местоположение пользователя.
  // Куки lat, lon устанавливается при использовании интерфейса Navigator
  // (window.navigator) в скрипте
  // app/javascript/packs/front/bem/affiliates_menu.js
  var lat = Cookies.get("lat")
  var lon = Cookies.get("lon")
  if (lat == null || lat == "" || lon == null || lon == "") {
    return null
  }
  AffiliateMap.map.geoObjects.add(
    new ymaps.Placemark(
      [lat, lon],
      {
        iconCaption: "Вы"
      },
      {
        preset: 'islands#governmentCircleIcon',
        iconColor: '#735184'
      }
    )
  )
}


$(document).on('turbolinks:load', function() {
  AffiliateMap.start()
})
