// Компоненты rails

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()




// Пакеты

global.jQuery = $
global.$ = $
global._ = _

import Cookies from 'js-cookie'
window.Cookies = Cookies

// require("jquery-ui/ui/widgets/sortable")
import * as sortable from "jquery-ui/ui/widgets/sortable"
import * as slider from "jquery-ui/ui/widgets/slider"

// import "bootstrap"
import * as bootstrap from "bootstrap"
window.bootstrap = bootstrap

// import Bloodhound from "typeahead.js/dist/typeahead.bundle.min.js"
import "../modules/typeahead.bundle"




// Дополнительные скрипты

import "./bootstrap"

import "./bem"
