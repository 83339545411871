"use strict"

$(document).on("turbolinks:load", function() {
  activate_offer_filter()
})

function activate_offer_filter() {
  $(".offer_filter").on("click", ".offer_filter-value", function(event) {
    var button = $(this)
    var offer = button.parents(".offer_filter-offer").first()
    var selected_filter = button.parents(".offer_filter").first().data("filter_id")
    var selected_value = button.data("value")
    button.addClass("selected").siblings(".offer_filter-value").removeClass("selected")
    var code = offer_filter_change_handler(offer, selected_filter, selected_value)
    var catalog_slug = offer.data("catalog_slug")
    var offer_slug = offer.data("offer_slug")
    offer_filter_ajax(catalog_slug, offer_slug, code, offer).done(offer_filter_set_product)
  })
  $(".offer_filter").on("change", ".offer_filter-select", function(event) {
    var select = $(this)
    var offer = select.parents(".offer_filter-offer").first()
    var selected_filter = select.parents(".offer_filter").first().data("filter_id")
    var selected_value = select.val()
    var code = offer_filter_change_handler(offer, selected_filter, selected_value)
    var catalog_slug = offer.data("catalog_slug")
    var offer_slug = offer.data("offer_slug")
    offer_filter_ajax(catalog_slug, offer_slug, code, offer).done(offer_filter_set_product)
  })
}

function offer_filter_change_handler(offer, selected_filter, selected_value) {
  // Исходные данных фильтров («Товары с их свойствами»)
  var filters_data = offer.data("filters")

  // Построим Хэш соответствий («Значения с соответствующими им Товарами») из Исходных данных фильтров.
  var filters_hash = {}
  Object.keys(filters_data).forEach((code) => {
    Object.keys(filters_data[code]).forEach((filter) => {
      var value_or_values = filters_data[code][filter]
      if (Object.prototype.toString.call(value_or_values) === '[object Array]') {
        value_or_values.forEach(value => {
          if (filters_hash[filter] == null) {
            filters_hash[filter] = {}
          }
          if (filters_hash[filter][value] == null) {
            filters_hash[filter][value] = []
          }
          filters_hash[filter][value].push(code)
        })
      } else {
        var value = value_or_values
        if (filters_hash[filter] == null) {
          filters_hash[filter] = {}
        }
        if (filters_hash[filter][value] == null) {
          filters_hash[filter][value] = []
        }
        filters_hash[filter][value].push(code)
      }
    })
  })

  // Определяем какие значения выбраны в Предложении.
  var selected = {}
  var filters = offer.find(".offer_filter")
  filters.each(function(index, dom) {
    var filter = $(dom)
    var filter_id = filter.data("filter_id")
    var value = null
    if (filter.find(".offer_filter-value").length != 0) {
      value = filter.find(".offer_filter-value.selected").first().data("value")
    } else if (filter.find(".offer_filter-select").length != 0) {
      value = filter.find(".offer_filter-select").first().val()
    }
    if (value != null) {
      selected[filter_id] = value
    }
  })

  // Исходя из выбранных значений фиьлтров (selected) выберем из Хэш
  // соответствий (filters_hash) массивы которые подвергнутся пересечению.
  var intersection_arrays = []
  for (var filter_id in selected) {
    var value = selected[filter_id]
    if (filters_hash[filter_id] != null && filters_hash[filter_id][value] != null) {
      intersection_arrays.push(filters_hash[filter_id][value])
    }
  }

  // Первое в пересечении = решению, если есть.
  var intersection = _.uniq(_.intersection(...intersection_arrays))
  var code = intersection[0]

  // Если нет пересечения (такое может быть), тогда верным решением будет
  // установка «правильных» Выбранных значений (right_selected). Правильные
  // выбранные значения вычисляются так: 1. Возьмём первый артикул из Хэша
  // соответствий (filters_hash) по данным клика
  // (selected_filter, selected_value). 2 По артикулу можем найти «правильные»
  // Выбранные значения (right_selected) из Исходных данных (filters_data).
  // 
  // Цель нахождения «правильных» Выбранных значений не новый расчёт
  // (right_code уже определён), а выставление Выбранных значений в
  // пользовательском итерфейсе.
  var right_code = null
  if (code == undefined) {
    var right_code = filters_hash[selected_filter][selected_value][0]
    var right_selected = filters_data[right_code]

    // Выставление Выбранных значений в пользовательском итерфейсе.
    filters.each(function(index, dom) {
      var filter = $(dom)
      var filter_id = filter.data("filter_id")
      if (filter.find(".offer_filter-value").length != 0) {
        filter.find(".offer_filter-value").each((index, dom) => {
          var value = $(dom)
          if (value.data("value") == right_selected[filter_id]) {
            value.addClass("selected")
          } else {
            value.removeClass("selected")
          }
        })
      } else if (filter.find(".offer_filter-select").length != 0) {
        var select = filter.find(".offer_filter-select").first()
        select.val(right_selected[filter_id])
      }
    })
  }

  return code || right_code
}

function offer_filter_ajax(catalog_slug, offer_slug, code, offer) {
  return $.ajax({
    type: "get",
    url: `/front/p/ajax_show/${catalog_slug}/${offer_slug}/${code}`,
    custom_data: {offer: offer},
    dataType: "json",
    cache: false,
    timeout: 3000
  })
  .fail(function(jqXHR, textStatus, errorThrown) {
    console.log('jqXHR.responseJSON: ', jqXHR.responseJSON)
    console.log('jqXHR.responseText: ', jqXHR.responseText)
  })
}

function offer_filter_set_product(data, textStatus, jqXHR) {
  // console.log('data: ', data)
  var offer = this.custom_data.offer
  var link  = offer.find(".offer_filter-link").first()
  var code  = offer.find(".offer_filter-code").first()
  var name  = offer.find(".offer_filter-name").first()
  var image  = offer.find(".offer_filter-image").first()
  var price  = offer.find(".offer_filter-price").first()
  var amount  = offer.find(".offer_filter-amount").first()
  var buy  = offer.find(".offer_filter-buy").first()
  link.attr("href", data.url)
  link.text(data.name)
  name.text(data.name)
  code.text(data.code)
  var image_url = offer.data("no_image")
  if (data.images.length > 0) {
    image_url = data.images[0].thumbnail.url
  }
  image.attr("src", image_url)
  price.html(data.html_price)
  amount.html(data.html_amount)
  buy.html(data.html_buy)
}
