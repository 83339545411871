"use strict"

var instance

// Класс для закрытия всплывших сообщений, см. вьюху
// app/views/application/_flashes.html.erb.
function Flashes() {
  this.options = {
    place: "#flashes",
    flash: ".flash",
    close: ".btn-close"
  }
}

Flashes.getInstance = function() {
  if (instance == null) {
    instance = new this()
  }
  return instance
}

Flashes.prototype.activate = function() {
  this.place = $(this.options.place)
  if (this.place.length != 1) {
    return this
  }
  this.place.on('click', this.options.close, {instance: this}, this.close_handler)
  this.place.on('click', this.options.flash, {instance: this}, this.close_handler)
  return this
}

Flashes.prototype.close_handler = function(event) {
  event.stopPropagation()
  var instance = event.data.instance
  var object = $(event.target)
  var flash = null
  // Определяем на что кликнули
  switch(true) {
    case object.is(instance.options.flash):
      // На "подложку"
      flash = object
    break
    case object.is(instance.options.close):
      // На "крестик"
      flash = object.parents(instance.options.flash).first()
    break
  }
  if (flash != null) {
    flash.addClass("closed")
  }
}

$(document).on('turbolinks:load', function() {
  Flashes.getInstance().activate()
})
